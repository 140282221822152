import React from "react"
// import L from "../images/petrine-logo.png"
import LB from "../images/petrine-logo-black.svg"

const Logo = () => {
  return (
    <div
      className="logo"
      style={{
        maxWidth: "510px",
      }}
    >
      <img src={LB} alt="" />
      {/* <img className="absolute top-0 left-0" src={L} /> */}
    </div>
  )
}

export default Logo

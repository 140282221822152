import React from "react"
import { graphql, Link } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "../components/seo"
import { _linkResolver } from "../core/utils"
import ExhibitionArticle from "../components/ExhibitionArticle"
import Exhibitions from "../components/Exhibitions"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { PrismicRichText } from "@prismicio/react"
import News from "../components/News"
import HomeFeed from "../components/HomeFeed"

export const pageQuery = graphql`
  query HomePageQuery {
    prismicHome {
      _previewable
      data {
        seo_title {
          text
        }
        seo_texte {
          text
        }
        seo_image {
          url
        }
        # news {
        #   item {
        #     richText
        #   }
        # }
        feed {
          item {
            document {
              ... on PrismicExhibition {
                type
                uid
                data {
                  artist {
                    document {
                      ... on PrismicArtist {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  title {
                    text
                  }
                  location
                  date_from(formatString: "DD.MM.YYYY")
                  date_to(formatString: "DD.MM.YYYY")
                }
              }
              ... on PrismicFair {
                type
                uid
                data {
                  title {
                    text
                  }
                  location
                  date_from(formatString: "DD.MM.YYYY")
                  date_to(formatString: "DD.MM.YYYY")
                }
              }
            }
          }
        }
        latest_exhibition {
          uid
          type
          document {
            ... on PrismicExhibition {
              type
              uid
              data {
                title {
                  text
                }
                subtitle {
                  text
                }
                date_from(formatString: "DD.MM.YYYY")
                date_to(formatString: "DD.MM.YYYY")
                texte {
                  richText
                }
                image_featured {
                  url
                  alt
                  dimensions {
                    width
                    height
                  }
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }
  }
`

const Home = ({ data: { prismicHome } }) => {
  const { seo_title, seo_texte, seo_image, news, latest_exhibition, feed } =
    prismicHome.data
  // const { allExhibitions, settings } = useSiteMetadata()
  // console.log(settings)
  return (
    <div className="home ">
      <Seo
        pageTitle={seo_title.text}
        pageDescription={seo_texte.text}
        pageBanner={seo_image.url}
        template="template-home"
        page={false}
      />
      <div className="md:grid- md:grid-cols-3 gap-lg">
        <div className="mb-lg col-span-2">
          {feed && <HomeFeed input={feed} />}
          {!feed && latest_exhibition && latest_exhibition.uid && (
            <Link to={_linkResolver(latest_exhibition.document)}>
              <ExhibitionArticle
                input={latest_exhibition.document.data}
                h1={false}
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default withPrismicPreview(Home)

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { _linkResolver } from "../core/utils"

const HomeFeedItem = ({ input }) => {
  const { data } = input.document
  const { title, date_from, date_to, artist, location } = data
  return (
    <div className="item mb-special">
      <Link to={_linkResolver(input.document)}>
        <div className="header">
          {artist && (
            <div className="subtitle">{artist.document.data.title.text}</div>
          )}

          <h2>{title.text}</h2>
          {date_from && date_to && (
            <div className="date">{`${date_from} — ${date_to}`}</div>
          )}
          {location && <div className="location">{location}</div>}
        </div>
      </Link>
    </div>
  )
}
const HomeFeed = ({ input }) => {
  return (
    <div className="home-feed">
      {input.map(({ item }, i) => (
        <HomeFeedItem key={i} input={item} />
      ))}
    </div>
  )
}
HomeFeed.propTypes = {
  input: PropTypes.array.isRequired,
}

export default HomeFeed
